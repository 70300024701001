import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    info: {
        backgroundColor: theme.palette.info.main,
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const InfoAlert = ({ message }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={10000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={clsx(classes.info)}
                aria-describedby="info-snackbar"
                message={
                    <span id="info-snackbar" className={classes.message}>
                        <InfoIcon className={classes.icon} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

export default InfoAlert;
