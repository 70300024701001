
import { ApolloConsumer } from 'react-apollo'
import { Redirect } from 'react-router-dom'

export default function LogOut() {
	return (
		<ApolloConsumer>
			{(client) => {
				client.resetStore()
				localStorage.removeItem('qSession')
				localStorage.removeItem('sessionExpirationDate')
				return <Redirect to='/' />
			}}
		</ApolloConsumer>
	)
}
