// @flow

import loadable from '@loadable/component'
import { ConfirmProvider } from 'material-ui-confirm'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import { ApolloProvider } from 'react-apollo'

import client from '../config/apollo'
import InfoAlert from '../utils/infoAlert/info.alert'
import ApolloNetworkStatusIndicator from './ApolloNetworkStatusIndicator'

const App = loadable(() => import('../App'))

const SetupApp = () => {
	return (
		<ApolloProvider client={client}>
			<SnackbarProvider
				SnackbarProps={{
					autoHideDuration: 5000,
					anchorOrigin: { vertical: "bottom", horizontal: "right" },
				}}
			>
				<ConfirmProvider>
					<ApolloNetworkStatusIndicator />
					<App />
				</ConfirmProvider>
			</SnackbarProvider>
		</ApolloProvider>
	);
};

export default SetupApp
