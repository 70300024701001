import moment from 'moment'
export { default as SessionHelper } from './sessionHelper'

export const csvToObject = (csvData) => {
	const objectKeys = csvData[0]
	const data = []
	if (csvData.length > 0) {
		csvData.shift()
		for (let csvRow = 0; csvRow < csvData.length; csvRow++) {
			const obj = {}

			for (let dataKey = 0; dataKey < objectKeys.length; dataKey++) {
				delete Object.assign(obj, {
					[objectKeys[dataKey]]: csvData[csvRow][dataKey]
				})
			}
			data.push(obj)
		}

		return data
	}
}

export function extractVars(template, openChar, closeChar) {
	let i = 0
	const data = []

	do {
		if (template[i + 1] === '{' && template[i] === '{') {
			for (let j = i + 2; j < template.length; j++) {
				if (template[j] === '}') {
					data[data.length] = template.slice(i + 2, j)
					i = j + 1
					break
				}
			}
		}
		if (template[i] === '{') {
			for (let t = i + 1; t < template.length; t++) {
				if (template[t] === '}') {
					data[data.length] = template.slice(i + 1, t)
					i = t + 1
					break
				}
			}
		}
	} while (i++ < template.length)

	return data
}

export function getDateFormatted(date) {
	return moment(date).format('LLL')
}

export function getDate(date) {
	const format = 'YYYY-MM-DD'
	return moment(date).format(format)
}

export const downdloadFile = ({ type, data, fileName }) => {
	const link = document.createElement('a');
	const file = new Blob([data], { type });

	link.href = URL.createObjectURL(file);
	link.download = fileName;
	link.click();

	URL.revokeObjectURL(link.href);
}

export const downloadJsonFile = ({ fileName = undefined, data }) => {
	if (typeof data !== 'string') return 'The data to be downloaded is not a string';

	downdloadFile({
		data,
		fileName: fileName ?? 'result.json',
		type: 'application/json',
	});
}

export function convertToCSV(json) {
	const header = Object.keys(json[0].content)
	const rows = json.map((item) => {
		const values = Object.values(item.content).map((value) => {
			return typeof value === 'string' && value.includes(',')
				? `"${value}"`
				: value
		})
		return values
	})
	rows.unshift(header)
	const csv = rows.map((row) => row.join(',')).join('\n')
	return csv
}

export function downloadCSV(csv) {
	const blob = new Blob([csv], { type: 'text/csv' })
	const url = URL.createObjectURL(blob)
	const link = document.createElement('a')
	link.href = url
	link.download = 'invalidCustomers.csv'
	link.click()
}