import { useSnackbar } from 'material-ui-snackbar-provider'
import { useEffect, useState } from 'react'
import { useApolloNetworkStatus } from '../config/apollo'

function getUserFriendlyErrorMessage(error) {
	if (error.networkError) {
		if (error.networkError.statusCode === 401) {
			return 'Su sesión ha caducado. Por favor inicia sesión nuevamente.'
		}
		return 'Error de red. Por favor verifique su conexión a Internet e inténtelo nuevamente.'
	}

	if (error.graphQLErrors) {
		const errorMessages = error.graphQLErrors.map((err) => {
			switch (err.extensions?.code) {
				case 'UNAUTHENTICATED':
					return 'Debes iniciar sesión para realizar esta acción.'
				case 'FORBIDDEN':
					return 'No tienes permiso para realizar esta acción.'
				case 'BAD_USER_INPUT':
					return 'Entrada inválida. Por favor revise sus entradas e inténtelo nuevamente.'
				case 'INTERNAL_SERVER_ERROR':
					return 'Ocurrió un error inesperado. Nuestro equipo ha sido notificado.'
				default:
					return err.message || 'Un error desconocido ocurrió.'
			}
		})
		return errorMessages.join(' ')
	}

	return 'Ocurrió un error inesperado. Por favor, inténtelo de nuevo más tarde.'
}

const ApolloNetworkStatusIndicator = ({ children }) => {
	const status = useApolloNetworkStatus()
	const snackbar = useSnackbar()
	const [lastError, setLastError] = useState(null)

	useEffect(() => {
		const handleError = (error) => {
			if (error && error !== lastError) {
				console.error('Apollo error:', error)
				const errorMessage = getUserFriendlyErrorMessage(error)
				snackbar.showMessage(errorMessage, 'Error', { variant: 'error' })
				setLastError(error)
			}
		}

		if (status.mutationError) {
			handleError(status.mutationError)
		}

		if (status.queryError) {
			handleError(status.queryError)
		}
	}, [status, snackbar, lastError])

	return children || null
}

export default ApolloNetworkStatusIndicator
