import LogOut from '../components/LogOut'

// Session Time in milliseconds - 24 Hours = 1000 * 60 * 60 * 24
const SESSION_TIME = 86400000

const SessionHelper = {
	get isSessionExpired() {
		return !!(
			Date.now() > Number(localStorage.getItem('sessionExpirationDate')) ||
			!localStorage.getItem('sessionExpirationDate')
		)
	},
	get currentUser() {
		let session = localStorage.getItem('qSession')
		let currentUser = null
		if (session) {
			session = JSON.parse(session)
			currentUser = session.currentUser

			// fallback roles
			if (currentUser) {
				currentUser = Object.assign({}, currentUser, {
					roles: currentUser.roles || currentUser.role || []
				})
			}
		}
		return currentUser
	},
	get token() {
		let session = localStorage.getItem('qSession')
		let token = ''
		if (session) {
			session = JSON.parse(session)
			token = session.token
		}
		return token
	},

	logIn(loginInfo) {
		localStorage.setItem('qSession', JSON.stringify(loginInfo))
		localStorage.setItem(
			'sessionExpirationDate',
			JSON.stringify(Date.now() + SESSION_TIME)
		)
	},
	logOut() {
		return <LogOut />
	}
}
export default SessionHelper
