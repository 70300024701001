import { offsetLimitPagination } from '@apollo/client/utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { split, concat } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'

import { SessionHelper } from '../utils'

const options = {
	httpUri: process.env.REACT_APP_GRAPHQL_API || 'http://localhost:4000/graphql',
	wsUri: process.env.REACT_APP_GRAPHQL_WS_API || 'ws://localhost:4000/graphql'
}

const { link: networkStatusLink, useApolloNetworkStatus } =
	createNetworkStatusNotifier()

const MAX_RETRIES = 5

const retryLink = new RetryLink({
	attempts: {
		max: MAX_RETRIES,
		retryIf: (error, _operation) => !!error && !error.result
	},
	delay: {
		initial: 1000,
		max: 30000,
		jitter: true
	}
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) =>
			// eslint-disable-next-line no-console
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		)
	}
	// eslint-disable-next-line no-console
	if (networkError) console.log(`[Network error]: ${networkError}`)
})

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				CommunicationLogs: offsetLimitPagination()
			}
		}
	}
})

let httpLink = createUploadLink({
	uri: options.httpUri
})

if (options.wsUri) {
	const token = SessionHelper.token
	const subscriptionClient = new SubscriptionClient(options.wsUri, {
		reconnect: true,
		connectionParams: () => ({
			authToken: token
		})
	})

	const wsLink = new WebSocketLink(subscriptionClient)

	httpLink = split(
		({ query }) => {
			const definition = getMainDefinition(query)
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			)
		},
		wsLink,
		httpLink
	)
}

const authLink = setContext((_, { headers }) => {
	const token = SessionHelper.token
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : ''
		}
	}
})

export { useApolloNetworkStatus, cache }

export default new ApolloClient({
	link: concat(
		networkStatusLink,
		concat(errorLink, concat(retryLink, authLink.concat(httpLink)))
	),
	cache,
	connectToDevTools: true
})
